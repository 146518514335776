import React from "react";
import { useField } from "formik";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";

const AutocompleteCountryField = ({
  controlId,
  label,
  name,
  type,
  inputGroupPrepend,
  options,
  placeholder = true,
}: {
  controlId: string;
  label?: string;
  name: string;
  type: string;
  inputGroupPrepend?: React.ReactNode;
  options: any;
  placeholder?: boolean;
}) => {
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <>
      <FormControl variant="standard" sx={{ minWidth: 230 }}>
        <Autocomplete
          id={field.name}
          onChange={(event: any, selected: any) => {
            if (selected) {
              helper.setValue(selected.label);
            }
          }}
          isOptionEqualToValue={(option, value) => {
            if (typeof value === "string") {
              return option.label === value;
            } else {
              return option == value;
            }
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label}
            </Box>
          )}
          onBlur={() => helper.setTouched(true)}
          value={field.value ? field.value : ""}
          defaultValue={meta.initialValue}
          options={options}
          sx={{ width: "100%" }}
          disableClearable
          renderInput={(params) => (
            <TextField
              error={isInvalid}
              helperText={isInvalid ? meta.error : ""}
              variant="standard"
              {...params}
              label={label}
            />
          )}
        />
      </FormControl>
    </>
  );
};

export default AutocompleteCountryField;
