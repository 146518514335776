let en = {
  homeTitle: "Home",
  homeText: "Register now",
  okButton: "Great",
  confirmDelete: "DELETE",
  signOut: "Sign Out",
  myProfile: "My\u00a0Profile",
  myProfileTitle: "My\u00a0Profile",
  myProfilePage: {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    nickname: "Nickname",
    photo: "Avatar",
  },
  ecommerceMenu: {
    ecommerceSettings: "E-Commerce\u00a0Settings",
    design: "Info Pages",
    customOptions: "Custom Options",
  },
  technicalMenu: {
    technicalSettings: "Technical Settings",
  },
  contactTitle: "Contact",
  contact: {
    addressLabel: "Address",
    contactEmailLabel: "Contact Email",
    contactPhoneLabel: "Contact Phone",
    embeddedMapLabel: "Google maps embed code",
  },
  settings: "Business",
  payments: "Payments",
  paymentsTitle: "Payments",
  noPaymentsAvailable: "No payments have been set up.",
  newPayment: "New Payment",
  editPayment: "Edit Payment",
  deletePayment: "Delete Payment",
  deletePaymentMessage:
    "Are you sure you want to delete the folowing payment method?",
  paymentLabel: "Payment Label",
  paymentInfo: "Info",
  paymentInfoDescription: "Shown in checkout",
  paymentDescription: "Description",
  paymentDescriptionDescription: "Instructions for payment",
  paymentType: "Type",
  savePayment: "Save Payment",
  save: "Save",
  paymentIban: "IBAN",
  activate: "Activate",
  deactivate: "Deactivate",
  stripeOnboardingButton: "Continue Onboarding",
  create: "Create",
  applicationTitle: "Application Settings",
  app: {
    domain: "Domain Names",
    customDomains: "Custom Domain Names",
    noCustomDomains: "No custom domains setup",
    setDomains: "Set up infrastructure domains first!",
    addCustomDomain: "Add Custom Domain Name",
    subdomain: "Subdomain",
    subdomainInfoHelp: "Use @ in the subdomain field if you don't want any.",
    simpleSetup: "Fast & Simple",
    simpleSetupDescription:
      'This option adds root and "www" records for all of your domains',
    deleteRecord: "Delete custom domain",
    deleteRecordMessage:
      "Are you sure you want to delete the following custom domain?",
    deployApplication: "Update Application",
    updateStarted: "Update started",
    helpEmailLabel: "Help contact email",
  },
  or: "or",
  infrastructureTitle: "Infrastructure",
  categoriesTitle: "Categories",
  newCategory: "New Category",
  editCategory: "Edit Category",
  categoryName: "Name",
  noCategoriesAvailable: "No categories have been set up.",
  categoryIsVisibleLabel: "Is visible",
  saveCategory: "Save Category",
  categories: {
    deleteCategoryTitle: "Delete Category",
    deleteCategoryDescription:
      "Are you sure you want to delete the following category?",
    deleteCategoryWithProductsTitle: "Delete Category with active products",
    deleteCategoryWithProductsDescription:
      "You are trying to delete a category which has active products!\n Please choose another category to set for the products",
    imageField: "Image",
    imageDescriptionField:
      "WEBP, PNG, JPG or SVG. Recommended: 512x512 pixels minimum.",

    newCategoryField: "New Category",
  },
  productsTitle: "Products",
  newProductTitle: "New Product",
  editProductTitle: "Edit Product",
  products: {
    noProductsAvailable: "No products.",
    newButton: "New Products",
    copyButton: "Duplicate",
    createNewProductButton: "Create Product",
    productNameField: "Product Name",
    skuField: "SKU",
    pnLabel: "PN",
    barcodeLabel: "Barcode",
    otherInfoLabel: "Other short info",
    brandField: "Brand",
    brandLogoField: "Brand Logo",
    brandLogoDescription: "Optimized WEBP, PNG, JPG or SVG.",
    sloganField: "Slogan",
    categoryField: "Category",
    hasStockLabel: "Use stock?",
    featured: "Featured Product",
    stockCountField: "Stock Count",
    stockLimitField: "Stock Limit",
    stockLimitDescription:
      "You will be notified when the stock reaches below this value.",
    unitOfMeasurementField: "UM",
    specificationsLabel: "Specifications",
    specificationName: "Name",
    specificationContent: "Content",
    priceField: "Price",
    priceCurrencyField: "Currency",
    taxPercentageField: "Tax Percentage",
    setTaxes: "Please set up taxes first!",
    descriptionField: "Description",
    benefitsField: "Benefits",
    imagesField: "Add images",
    discoutLabel: "Discount",
    discountStartDateField: "Start Date",
    discountEndDateField: "End Date",
    isDiscountPercentageField: "Percentage Value?",
    productDiscountPriceField: "Sale price",
    imagesDescriptionField:
      "Click to upload or drag&drop WEBP, PNG or JPG images. Recommended width: 512 pixels minimum.",
    editImage: "Edit Image",
    setMainImage: "Set image as main",
    imageAltLabel: "Image Alt Text",
    visibilityField: "Visibility",
    isVirtualLabel: "Virtual Product",
    weightLabel: "Weight (g)",
    lengthLabel: "Length (cm)",
    widthLabel: "Width (cm)",
    heightLabel: "Height (cm)",
    costLabel: "Product cost",
    isVariableField: "Is Variable",
    variationOptionalField:
      "Fill this field only if you want specific values for this variation",
    variationNameLabel: "Attribute",
    productCreatedAlert: "Product created.",
    selectCategoryLabel: "Select a category",
    parentCategory: "Select a parent Category",
    editProductButton: "Edit Product",
    productErrorAlert:
      "There was an error. Please try again or contact suppport.",
    productEditedAlert: "Product edited",
    basic: "Basic details",
    basicDescription: "Minimum details to get started",
    description: "Marketing",
    descriptionDescription: "All about the product",
    variations: "Variations",
    variationsDescription: "Set up variable products",
    isDraft: "Is draft (will not appear on website at all).",
    inventory: "Inventory",
    inventoryDescription: "Keep track of your bussiness",
    productFormSuccess: "Product saved!",
    productFormError: "Error. Try again or contact support.",
    deleteProduct: "Delete Product",
    deleteProductMessage:
      "Are you sure you want to delete the following product?",
    deletedProduct: "Deleted product",
    uploadImageUrl: "Upload Image URLs",
    imageUrlsMultiple: "Image URLs (one per line)",
    imageUrlsSingle: "Image URL",
    invalidUrls: "Some of the URLs are invalid",
  },
  productsExportImportTitle: "Import/Export",
  productsExportImport: {
    importFile: "Import File",
    importDescriptionField: "Click to upload or drag&drop CSV file.",
    import: "Import",
    fileSelected: "File selected",
    uploadSuccess: "File imported with success!",
    productsImported: "products imported!",
    bulkUploadImages: "Bulk upload images",
    uploadImages: "Upload Images",
    uploadingImages: "Uploading images for products",
    doNotClose: "Please do not close the window!",
    publishProducts: "Publish new products",
    uploaded: " uploaded!",
    closeAndGoToProducts: "Close & go to products",
    done: "Done!",
    downloadExampleCSV: "Download Example CSV",
    uploadingProductsTitle: "Importing products",
    uploadingProductsMessage:
      "It may take a few minutes, please don't close the page.",
    exportProducts: "Export Products",
    noCsvImports: "No file imports",
    csvImports: "CSV Imports",
  },
  dnsZoneTitle: "Dns Zone",
  templates: {
    simplicity: "Simplicity",
  },
  dnsZone: {
    title: "Dns Zone",
    newDnsZone: "New DNS Zone",
    domainName: "Domain Name",
    noDnsZone: "No DNS zones",
    isManagedLabel: "Managed by DROP",
    isNotManagedLabel: "Self managed",
    status: "Status",
    nameServers: "Nameservers",
    checkNameserversButton: "Check Nameservers",
    setUpManaged: "Set Up Domain",
    setUpUnamaged: "Manage my own domain",
    customDomainsTitle: "Custom domains",
    noCustomDomains: "No custom domains set up",
    addNewDomain: "Add new custom domain",
    invalidDomainNameError: "Enter a valid domain name (root)",
    addButton: "Add",
    seeDetailsButton: "Details",
    rootDomainText: "Use @ for root domain.",
    domainNameTextField: "Domain Name",
    saveDomainNames: "Save",
    mailDomainTitle: "Email domain",
    mailNotSet: "Email domain not set",
    setEmailButton: "Set up email",
    deleteDnsZone: "Delete Zone",
    deleteRecord: "Delete Record",
    deletedDnsZone: "Zone deleted",
    newDnsZoneSuccess: "Zone created",
    setUpNameServersFirst: "Please set up and verify nameservers first!",
    deleteZoneMessage: "Are you sure you want to delete the folowing zone?",
    deleteRecordMessage: "Are you sure you want to delete the folowing record?",
    newCloudflareCheckSuccess: "Check successfully initiated.",
    records: "Records",
    addRecord: "Add Record",
    editRecord: "Edit Record",
    recordNameLabel: "Name",
    recordTypeLabel: "Type",
    recordValueLabel: "Value",
    recordPriorityLabel: "Priority",
    recordTtlLabel: "TTL",
    deletingRecordFromAppWarning:
      "Warning! This will remove the custom domain from your app!",
    deletingEmailRecordWarning:
      "Warning! This action will break transactional email functionality!",
  },
  businessSettingsHeader: "General Settings",
  brandBookHeader: "Brand Book",
  brandBook: {
    sloganLabel: "Slogan",
    sloganDescription:
      "A Brand statement used for clarity, memorability or marketing",
    shortDescriptionLabel: "Short Description",
    addSocialLinkLabel: "Add Social Media Links",
    shortDescriptionDescription:
      "Provide a concise and informative summary of a website's content and purpose, and to entice users to click through to the site",
    logoLabel: "Logo",
    logoDescriptionLabel:
      "WEBP, PNG, JPG or SVG. Recommended width: 512 pixels minimum.",
    logoSquareLabel: "Square Logo for Social Media",
    logoSquareDescriptionLabel:
      "WEBP, PNG, JPG or SVG. Recommended: 512×512 pixels minimum.",
    coverImageLabel: "Cover/Hero Image",
    coverImageDescriptionLabel:
      "WEBP, PNG or JPG or SVG. Recommended: 1920x384 pixels minimum.",
    primaryColorLabel: "Primary Color",
    primaryColorDescription:
      "This is the dominant color used usually chosen based on the brand's color palette.",
    secondaryColorLabel: "Secondary Color",
    secondaryColorDescription:
      "The secondary color is usually used for accents, such as links, buttons, and other interactive elements.",
    colorContrastWarning: "The contrast is too low, it might not look good.",
    saveBrandBook: "Save",
  },
  infrastructureHeader: "Infrastructure",
  customOptions: "Business settings",
  salesChannelsTitle: "Online Sales Channel",
  salesChannels: {
    header: "Online Sales Channel",
  },
  policiesTitle: "Policies",
  policies: {
    editPolicy: "Edit Policy",
    newPolicy: "New Policy",
    noPolicies: "No policies",
    copyUrl: "Copy Url",
    copiedUrl: "URL copied!",
    lastUpdated: "last updated on",
    name: "Name",
    slugLabel: "URL slug (optional)",
    typeLabel: "Type",
    deletePolicy: "Delete Policy",
    deletePolicyMessage:
      "Are you sure you want to delete the folowing payment policy?",
    successDelete: "Policy deleted!",
    dynamicData: "Insert Dynamic Data",
    updatedAt: "Last Updated Date",
    contactEmail: "Contact Email",
    supportEmail: "Support Email",
    supportPhoneNumber: "Support Phone Number",
    searchData: "Search Data",
    generateFromTemplate: "Generate from template",
    policySaved: "Policy Saved",
    policyUpdated: "Policy Updated",
  },
  shippingTitle: "Shipping",
  shipping: {
    methodsTab: "Methods",
    integrationsTab: "Integrations",
    noCouriers: "No courier integration set up",
    courierFormTitle: "Courier integration",
    nameLabel: "Name",
    courierLabel: "Courier",
    usernameLabel: "Username",
    passwordLabel: "Password",
    clientIdLabel: "Client ID",
    pickupPointLabel: "Pick-Up point",
    finishSetUp: "Finish set up!",
    courierDeleteConfirmMessage:
      "Are you sure you want to delete the following courier?",
    shippingMethodDeleteConfirmMessage:
      "Are you sure you want to delete the following shipping method?",
    newCourierButton: "New Courier",
    newShippingMethodButton: "New Shipping Method",
    noShippingMethods: "No shipping methods",
    shippingMethodFormTitle: "Shipping Method",
    typeLabel: "Type",
    typeCourier: "Courier Integration",
    typeSimple: "Simple",
    descriptionLabel: "Description",
    descriptionFieldDescription: "Internal description of shipping method",
    infoLabel: "Info",
    infoFieldDescription: "Shown in checkout",
    priceLabel: "Price",
    freeTresholdLabel: "Free shipping treshold",
    freeTresholdDescription:
      "The net value of the cart where the shipping will be free",
    discountsInTresholdLabel: "Include discounts in free treshold",
    discountsInTresholdDescription:
      "If you want to include discounts in the treshold where shiping will be free",
    setUpCouriersFirst: "Please set up a courier integration first!",
    serviceLabel: "Service",
    zonesTab: "Zones",
  },
  invoicesTitle: "Invoices",
  newInvoiceTitle: "New Invoice",
  newRecurrenceTitle: "New Recurrence",
  editRecurrenceTitle: "Edit Recurrence",
  editInvoiceTitle: "Edit Invoice",
  invoices: {
    invoice: "Invoice",
    invoicesList: "Invoices",
    invoiceTemplates: "Recurring Invoices",
    customerNotSelected: "Please select a customer",
    billAddressNotSelected: "Please select an adress",
    missingLineName: "Invoice line item name required",
    missingLineQty: "Invoice line item quantity required",
    missingLineUnitPrice: "Invoice line item amount required",
    missingInvoiceLines: "Invoice lines are required",
    paymentMethodNotSelected: "Please select a payment method",
    status: "Status",
    dueDate: "Due Date",
    newInvoice: "New Invoice",
    newRecurrence: "New Recurrence",
    noInvoices: "No Invoices",
    lineDiscount: "Line Discount",
    invoiceDiscount: "Invoice Discount",
    invoiceDetails: "Invoice Details",
    recurrence: "Recurrence",
    series: "Series",
    currency: "Currency",
    exchangeRate: "Rate",
    invoiceLines: "Invoice Lines",
    item: "Item",
    description: "Description",
    qty: "Qty",
    unitPrice: "Unit Price",
    amount: "Amount",
    tax: "VAT %",
    totalVat: "Total VAT",
    totalDiscounts: "Discounts",
    vatIncluded: "VAT included",
    um: "UM",
    discount: "",
    addDiscount: "Add Discount",
  },
  taxesTitle: "Taxes",
  taxes: {
    taxIncluedLabel: "Tax included in price",
    setTaxesLabel: "Set taxes",
    labelLabel: "Tax Label (optional)",
    taxesSaved: "Taxes Saved",
    taxPercentageRequired: "The percentage value is required!",
    updateProductsPriceTitle: "Do you want to update products net price?",
    updateProductsPriceDescription:
      "You have products that use this tax. \nDo you want to update the net price with the new tax percentage value?",
    deactivateTaxTitle: "Deactivate tax with active products",
    deactivateTaxDescription:
      "You are trying to deactivate a tax which has active products!\n Please choose another tax to set for the products, and if you want to update the products net price with the new tax difference.",
  },
  localizationSettingsTitle: "Localization settings",
  localizationTitle: "Localization",
  localization: {
    storeLocaleLabel: "Default language",
    enabledLocales: "Enabled languages",
    timezone: "Timezone",
    selectDefaultLanguage: "Please select a default language!",
    saveAndNextLanguage: "Save & Next Language",
    saveAndNextItem: "Save & Next Item",
  },
  currencyTitle: "Currencies",
  currency: {
    defaultCurrencyLabel: "Default Currency",
    enabledCurrenciesLabel: "Enabled Currencies",
    currencySaved: "Currency settings saved",
    selectDefaultCurrency: "Please select a default currency",
  },
  invoiceTitle: "Invoices",
  invoice: {
    invoice: "Invoice",
    invoiceSeriesLabel: "Invoice Series",
    invoiceGenerated: "Invoice Generated",
  },
  invoiceSeries: {
    title: "Invoice Series",
    editInvoiceSeries: "Edit Invoice Series",
    newInvoiceSeries: "New Invoice Series",
    seriesLabel: "Series",
    defaultInvoiceSeries: "Default",
    descriptionLabel: "Description",
    firstNumberLabel: "First invoice number",
    currentNumberLabel: "Current number",
    deleteInvoiceSeries: "Delete Invoice Series",
    deleteInvoiceSeriesMessage:
      "Are you sure you want to delete the following invoice series?",
    noInvoiceSeries: "No invoice series have been set up.",
    setDefaultLabel: "Set as default series.",
  },
  waitForSettings: "Please wait a bit for setup to finish.",
  signUpButton: "Sign Up",
  signUpTitle: "Sign Up",
  signUpHeader1: "Start dropping your work.",
  signUpHeader2: "Sign up now.",
  signUpFooter: "Keep doing what you do best. The boring stuff is on us.",
  signUpAlready: "You have an account?",
  signUpAlreadyLink: "Sign in here",
  signUpFirstNameLabel: "First Name",
  signUpLastNameLabel: "Last Name",
  signUpEmailLabel: "Email",
  signUpPasswordLabel: "Password",
  signUpButtonLabel: "Start your journey",
  signUpShortError: "Too short, please use more than 2 characters!",
  signUpLongError: "Too Long!",
  signUpEmailError: "Invalid email",
  required: "Required",
  signInHeader: "Do your work. Login to your account.",
  signInMessage: "Forgot password? Reset it.",
  signInButton: "Sign in",
  signInAlready: "You don't have an account?",
  signInAlreadyLink: "Sign up",
  signInInvalidCredentials: "User or password is incorrect!",
  verifyTitle: "Verify your email",
  verifyInstruction: "Please click the button below to confirm your email.",
  verifyButtonText: "Confirm",
  verifyHeader: "Congratulations!",
  verifyHeaderMessage: "Your account was succesfully created.",
  verifyMessage: "Now check your email to confirm your adress.",
  verifyHeaderVerifiedMessage: "Your email was succesfully verified!",
  verifyEmailError:
    "User created but verification email failed. Please contact support.",
  passwordResetTitle: "Reset Password",
  passwordResetHeader: "Reset Password",
  passwordResetMessage:
    "Type your email and we will send you an email with instructions to reset your password.",
  passwordResetRequestError:
    "There was an error sending your reset email. Please try again or contact support.",
  passwordResetRequestButton: "Reset Password",
  passwordResetRequestSuccess:
    "An email has been sent to the address if an accout exists.",
  passwordResetFormMessage: "Type your new password below to change it.",
  passwordResetSuccess: "You password has been succesfully updated.",
  passwordResetError:
    "There was an error reseting your password. Please try again or contact support.",
  resendVerificationEmailButton: "Resend email",
  resendVerificationEmailSuccess: "Email was sent succesfully",
  dashboardTitle: "Dashboard",
  reportsTitle: "Reports",
  marketingTitle: "Marketing",
  helpdeskTitle: "Helpdesk",
  customersTitle: "Customers",
  newCustomerTitle: "New Customer",
  viewCustomerTitle: "View Customer",
  customers: {
    newCustomer: "New Customer",
    noCustomers: "No Customers",
    customer: "Customer",
    address: "Address",
    newAddress: "New Address",
    editAddress: "Edit Address",
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    phoneNumberLabel: "Phone Number",
    emailLabel: "Email",
    addressNameLabel: "Contact name",
    streetAddressLabel: "Street Address",
    cityLabel: "City",
    countyLabel: "County",
    countryLabel: "Country",
    contactPhoneNumberLabel: "Contact Phone Number",
    labelLabel: "Label",
    postalCodeLabel: "Postal Code",
    companyNameLabel: "Company Name",
    vatIdLabel: "VAT ID",
    fiscalIdLabel: "Fiscal ID",
  },
  ordersTitle: "Orders",
  orderTitle: "Order",
  newOrderTitle: "New Order",
  order: {
    generateInvoice: "Generate Invoice",
    pending: "Pending",
    abandoned: "Abandoned",
    processing: "Processing",
    shipped: "Shipped",
    canceled: "Canceled",
    completed: "Completed",
    advanced: "Advanced",
    partiallyShipped: "Partially Shipped",
    returned: "Returned",
    refunded: "Refunded",
    disputed: "Disputed",
    paymentMethod: "Payment Method",
    shippingMethodRequired: "Please select a shipping method",
    paymentMethodRequired: "Please select a payment method",
    customerRequired: "Please select a customer",
    shippingAddressRequired: "Please select a shipping address",
    billingAddressRequired: "Please select a billing address",
    customer: "Customer",
    shippingAddress: "Shipping Address",
    billingAddress: "Billing Address",
    delivery: "Delivery",
    generateAwb: "Generate AWB",
    products: "Products",
    addProduct: "Add Product",
    addProductDescription:
      "Search below for a product. Type at least 2 characters.\nOnly first 10 results are shown",
    search: "Search...",
    searchProduct: "Search product",
    searchProductHelp: "2+ chars needed, 10 results max",
    setCustomer: "Set Customer",
    setCustomerDescription:
      "Search an email below for a customer. Type at least 2 characters.\nOnly first 10 results are shown",
    noOrders: "No orders",
    usedCoupon: "Used Coupons",
    applyDiscount: "Apply Discount",
    productDiscount: "Product Discount",
    orderDiscount: "Order Discount",
    discountPercentage: "Discount Percentage",
    discountAmmount: "Amount",
    discountSalePrice: "Final Price",
    shipping: "Shipping",
    subtotal: "Subtotal",
    totalDiscount: "Total Discount",
    total: "Total",
    productsSubtotal: "Products Subtotal",
    productsTotalDiscount: "Total Discount",
    productsTotal: "Products Total",
    discountAmmountRequired: "Please enter a discount ammount!",
    editProducts: "Edit Products",
    editProductsConfirmation:
      "Are you sure you want to save the new order products?",
    saved: "Order saved successfully",
  },
  doTitle: "DO",
  do: {
    newDoLabel: "Write a to-do and hit enter...",
    noDos: "You are all caught up!",
    dueDate: "Due Date",
    newDo: "Create DO",
    editDo: "Edit DO",
    nameLabel: "Name",
    descriptionLabel: "Description",
    dueDateLabel: "Due Date",
    doneDate: "Done Date",
    doneLabel: "Done",
    tagsLabel: "Tags",
    deleteConfirmMessage: "Are you sure you want to delete the following DO?",
  },
  settingsTitle: "Settings",
  companyInfoTitle: "General\u00a0Settings",
  subscriptionsTitle: "Subscriptions",
  newSubscriptionTitle: "New Subscription",
  editSubscriptionTitle: "Edit Subscription",
  subsTypesTitle: "Types",
  subscriptions: {
    noSubscriptionsAvailable: "No Subscriptions",
    newButton: "New Subscription",
    copyButton: "Duplicate",
    createNewSubscriptionButton: "Create Subscription",
    subscriptionNameField: "Subscription Name",
    skuField: "SKU",
    pnLabel: "PN",
    barcodeLabel: "Barcode",
    otherInfoLabel: "Other short info",
    brandField: "Brand",
    sloganField: "Slogan",
    categoryField: "Type",
    imageField: "Image",
    imageDescriptionField:
      "WEBP, PNG, JPG or SVG. Recommended width: 512 pixels minimum.",
    priceField: "Price",
    priceCurrencyField: "Currency",
    taxPercentageField: "Tax Percentage",
    setTaxes: "Please set up taxes first!",
    descriptionField: "Description",
    imagesField: "Add images",
    discoutLabel: "Discount",
    imagesDescriptionField:
      "Click to upload or drag&drop WEBP, PNG or JPG images. Recommended width: 512 pixels minimum.",
    editImage: "Edit Image",
    setMainImage: "Set image as main",
    imageAltLabel: "Image Alt Text",
    visibilityField: "Visibility",
    selectCategoryLabel: "Select a category",
    editSubsButton: "Edit Subscription",
    basic: "Basic details",
    basicDescription: "Minimum details to get started",
    description: "Marketing",
    descriptionDescription: "All about the product",
    inventory: "Inventory",
    inventoryDescription: "Keep track of your bussiness",
    newSubsCategory: "New Type",
    editSubsCategory: "Edit Type",
    noSubsCategoriesAvailable: "No types",
    deleteSubsCategoryWithProductsTitle:
      "Delete type with active subscriptions",
    deleteSubsCategoryTitle: "Delete Subscription Type",
    deleteSubsCategoryWithProductsDescription:
      "You are trying to delete a type which has active subscriptions!\n Please choose another type to set for the subscriptions",
    deleteSubsCategoryDescription:
      "Are you sure you want to delete the following subscription type?",
    newSubsCategoryField: "New Type",
    deleteSubscription: "Delete Subscription",
    deleteSubscriptionMessage:
      "Are you sure you want to delete the following subscription?",
    deletedSubscription: "Deleted product",
    frequenciesLabel: "Frequencies",
    prepaidDiscount: "Prepaid Discount",
    monthly: "Monthly",
    quarterly: "Quarterly",
    yearly: "Yearly",
  },
  newsletterSubscribersTitle: "Newsletter",
  newsletterSubscribersSettingsTitle: "Newsletter Settings",
  newsletterSubscribersTab: "Newsletter",
  newsletterSubscribers: {
    noNewsletterSubscribers: "No subscribers",
    firstName: "First Name",
    lastName: "Last Name",
    birthday: "Birthday",
    phoneNumber: "Phone Number",
    ip: "Ip",
    source: "Source",
    export: "Export Subscribers",
    enableNewsletterForm: "Enable newsletter form",
    newsletterFormType: "Form Type",
    simpleType: "Simple",
    mediumType: "Medium",
    fullType: "Full",
  },
  subsCategoriesTitle: "Subscription Types",
  membershipsTitle: "Memberships",
  memberships: {},
  billingTitle: "Billing",
  infraWizardTitle: "Infrastructure wizard",
  infraWizardHeader: "Start dropping your work. It's easy.",
  tenant: {
    vatNumber: "VAT No.",
    regNo: "Reg No.",
    otherId: "Other ID No.",
    shareCapital: "Share Capital",
  },
  companyName: "Company short name",
  companyLegalName: "Company legal name",
  domainName: "Domain Name (ex: mydomain.com)",
  receivingEmail: "Default email for receiving (ex: shop@mydomain.com)",
  supportEmail: "Default email for support (ex: help@mydomain.com)",
  sendingEmail: "Default email for sending (ex: no-reply@mydomain.com)",
  addressLine1: "Address line 1",
  addressLine2: "Address line 2",
  city: "City",
  state: "State/County",
  zipCode: "Zip Code",
  country: "Country",
  infraWizardButton: "Start dropping your work",
  createNewCompanyHeader: "Create a new company",
  createNewCompanyButton: "Create Company",
  newCompanyCongrulations: "Congratulations!",
  newCompanyCongrulationsMessage:
    "<p>Masters of AI are deploying your services as we write😊. Chill out for a few moments and our Digital Assistant will let you know when everything is ready to be used. </p> <p><strong>Kidding.</strong></p> <p>We’re not using AI or Digital Assistants. We like people and the platform just automates some boring things. <br/><strong>Long live humanity!</strong></p>",
  updateCompanyButton: "Update",
  companyInfo: {
    deleteCompany: "Delete Company",
    deletedCompany: "Company deleted",
    deleteCompanyMessage:
      "Are you sure you want to delete the folowing company?",
    deleteCompanyTypeName: "Type the short name of the company below:",
  },
  couponsTitle: "Coupons",
  createCouponsTitle: "Create Coupon",
  editCouponTitle: "Edit Coupon",
  coupons: {
    noCoupons: "No coupons.",
    newCoupon: "New coupon",
    nameLabel: "Name",
    generateName: "Generate random name",
    descriptionLabel: "Description",
    isPercentageLabel: "Fixed or Percent Rate",
    fixedOption: "Fixed amount",
    percentageOption: "Percentage discount",
    amountLabel: "Amount",
    endDateLabel: "Expiry date",
    worksWithDiscountLabel: "Works with other discounts",
    userUsesLimitLabel: "Limit per user",
    userUsesLimitInfo: "Number of uses per user.\nUse 0 for unlimited.",
    usesLimitLabel: "Limit per coupon",
    usesLimitInfo:
      "Number of times the coupon can be used.\nUse 0 for unlimited.",
  },
  croTab: "CRO",
  croTitle: "Conversion rate optimization",
  cro: {
    freeShippingMessageEnabledLabel:
      "Show free shipping message if you offer free shipping",
    freeShippingMessageLabel:
      "Informative message about your free shipping option",
    latestProductsLabel: "Latest products section title",
    featuredProductsLabel: "Featured products section title",
  },
  consentTab: "Consent",
  consentTitle: "Consent",
  consent: {
    modalTextLabel: "Modal Text",
    acceptAll: "Accept All Button",
    rejectAll: "Reject All Button",
    confirm: "Confirm Button",
    marketingName: "Marketing purpose",
    marketingDescription: "Marketing description",
  },
  integrationsTitle: "Integrations",
  integrations: {
    gtmLabel: "Google Tag Manager",
    ga4Label: "Google Analytics",
    pixelLabel: "Facebook Pixel",
    mailchimpLabel: "MailChimp",
  },
  footerCopyright: "@2022 - TBD Company",
  addNew: "Add new",
  closeButton: "Close",
  cancelButton: "Cancel",
  nextButton: "Next",
  unkownError: "Unkown error. Please contact support!",
  loading: "loading..",
  filters: "Filters",
  status: "Status",
  saveAndCloseButton: "Save & Close",
  saveButton: "Save",
  saveAndBackButton: "Save & Go Back",
  saveAndNewButton: "Save & New",
  editButton: "Edit",
  savedMessage: "Saved",
  deletedMessage: "Deleted",
  deleteButton: "Delete",
  possibleDataLossWarning: "Attention! This could result in data loss!",
  yes: "Yes",
  no: "No",
};

export default en;
