export const orderStatuses = [
  {
    slug: "pending",
    label: "Pending",
    active: 0,
  },
  {
    slug: "abandoned",
    label: "Abandoned",
    active: -1,
  },
  {
    slug: "processing",
    label: "Processing",
    active: 1,
  },
  {
    slug: "shipped",
    label: "Shipped",
    active: 3,
  },
  {
    slug: "partially_shipped",
    label: "Partially Shipped",
    active: 2,
  },
  {
    slug: "completed",
    label: "Completed",
    active: 4,
  },
  {
    slug: "canceled",
    label: "Canceled",
    active: -2,
  },
  {
    slug: "returned",
    label: "Returned",
    active: -3,
  },
  {
    slug: "refunded",
    label: "Refunded",
    active: -4,
  },
  {
    slug: "disputed",
    label: "Disputed",
    active: -5,
  },
];
