import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import { Formik } from "formik";
import {
  createContact,
  getContactByTenant,
  updateContact,
} from "services/tenants";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { IContact } from "interfaces";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { LoadingButton } from "@mui/lab";
import InfoPopover from "components/InfoPopover";
import DropZoneBrandField from "components/Form/DropZoneBrandField";
import ColorInputField from "components/Form/ColorInputField";
import ArraySocialField from "components/Form/ArraySocialField";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";

const Contact = () => {
  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();
  const [contact, setContact] = useState<IContact | null>(null);
  const [contactLoading, setContactLoading] = useState(true);
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const translation = useTranslation();
  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
    setContactLoading(true);

    if (tenants !== null) {
      getContactByTenant(tenants[selectedTenant].id)
        .then((res) => {
          if (res.data["hydra:member"].length > 0) {
            console.log(res.data["hydra:member"][0]);
            setContact(res.data["hydra:member"][0]);
          } else {
            setContact(null);
          }
          setContactLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  return (
    <>
      <Formik
        initialValues={
          contact
            ? {
                address: contact.address,
                contactPhone: contact.contactPhone,
                contactEmail: contact.contactEmail,
                embeddedMap: contact.embeddedMap,
              }
            : {
                address: "",
                contactPhone: "",
                contactEmail: "",
                embeddedMap: "",
              }
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);

          if (!contact) {
            createContact({
              address: values.address,
              contactPhone: values.contactPhone,
              contactEmail: values.contactEmail,
              embeddedMap: values.embeddedMap,
              tenant: "/tenants/" + tenants[selectedTenant].id,
            })
              .then(() => {
                setUpdateTrigger(!updateTrigger);
                setSuccessMessage(translation.savedMessage);
                actions.setSubmitting(false);
              })
              .catch((e) => {
                setErrorMessage(errorMessage(e));
                actions.setSubmitting(false);
              });
          } else {
            updateContact({
              id: contact.id,
              address: values.address,
              contactPhone: values.contactPhone,
              contactEmail: values.contactEmail,
              embeddedMap: values.embeddedMap,
            })
              .then(() => {
                setUpdateTrigger(!updateTrigger);
                setSuccessMessage(translation.savedMessage);
                actions.setSubmitting(false);
              })
              .catch((e) => {
                setErrorMessage(errorMessage(e));
                actions.setSubmitting(false);
              });
          }
        }}
        enableReinitialize
      >
        {({ handleSubmit, errors, isSubmitting, values }) => {
          return (
            <form noValidate className="" onSubmit={handleSubmit}>
              <Grid container>
                <Grid item md={6}>
                  <Stack spacing={2}>
                    <Paper sx={{ p: 2 }}>
                      <Stack spacing={4}>
                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.contact.addressLabel}
                                multiline
                                lineRows={false}
                                name="address"
                              />
                            </Stack>
                          </Box>
                          {/* <InfoPopover
                            infoContent={translation.contact.sloganDescription}
                          /> */}
                        </Stack>

                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.contact.contactEmailLabel}
                                name="contactEmail"
                              />
                            </Stack>
                          </Box>
                          {/* <InfoPopover
                            infoContent={
                              translation.contact.contactPhoneLabel
                            }
                          />
                          */}
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.contact.contactPhoneLabel}
                                name="contactPhone"
                              />
                            </Stack>
                          </Box>
                          {/* <InfoPopover
                            infoContent={
                              translation.contact.contactPhoneLabel
                            }
                          />
                          */}
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <Box flexGrow={1}>
                            <Stack>
                              <FormTextField
                                label={translation.contact.embeddedMapLabel}
                                multiline
                                lineRows={false}
                                name="embeddedMap"
                              />
                            </Stack>
                          </Box>
                          {/* <InfoPopover
                            infoContent={translation.contact.sloganDescription}
                          /> */}
                        </Stack>
                      </Stack>
                    </Paper>

                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        type="submit"
                      >
                        {translation.saveButton}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Contact;
