import React from "react";
import useTranslation from "components/customHooks/translations";
import { useLocation, useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FilterBAndWOutlined, KebabDiningOutlined } from "@mui/icons-material";

interface LinkTabProps {
  label?: string;
  href?: string;
  value?: string;
  icon?: any;
}

function LinkTab(props: LinkTabProps) {
  return <Tab sx={{ minHeight: "50px" }} iconPosition="start" {...props} />;
}

const EcommerceMenu = () => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(newValue);
  };

  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <Tabs
        onChange={handleChange}
        value={
          pathname.includes("design")
            ? routeNames.policies
            : routeNames.applicationSettings
        }
        variant="fullWidth"
      >
        <LinkTab
          icon={<FilterBAndWOutlined />}
          label={translation.ecommerceMenu.design}
          value={routeNames.policies}
        />
        <LinkTab
          icon={<KebabDiningOutlined />}
          label={translation.ecommerceMenu.customOptions}
          value={routeNames.payments}
        />
      </Tabs>
    </Box>
  );
};

export default EcommerceMenu;
